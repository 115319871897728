import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import TimeInput from "react-time-picker-input";
import cx from "classnames";

import "./TimePicker.scss";

export const TimePicker = ({ className, name, value, readOnly, onChange }) => {
  const handleChange = (date) => {
    name ? onChange(name, date) : onChange(date);
  };

  return (
    <div className={cx("custom-time-picker", className)}>
      <TimeInput
        name={name}
        value={value}
        onChange={(e) => (readOnly ? "" : handleChange(e))}
      />
    </div>
  );
};
