import * as types from "../actions/loginAction";

import { getAccessToken } from "../../utils/authorization";

const initialState = {
  user: {},
  isLoggedIn: !!getAccessToken() || false,
  isLoading: false,
  errorMessage: "",
  role: "",
  restaurant: null,
  restaurantNumber: null,
  accessList: [],
  pages: [],
  verification: !!localStorage.getItem("multiFactorAuth"),
  verified: !localStorage.getItem("multiFactorAuth"),
  previous: "/settings",
  changePasswordClicked: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return { ...state, isLoading: true };

    case types.LOGIN_USER_RESULT:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: action.payload.user,
        verified: !action.payload.user?.multiFactorAuthentication,
      };

    case types.LOGIN_USER_ERROR:
      return { ...state, isLoading: false, errorMessage: action.message };

    case types.GET_ME:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_ME_RESULT:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        role: action.payload.user.type,
        restaurant: action.payload.user?.restaurantUuid,
        restaurantNumber: action.payload.user?.restaurantNumber,
        accessList: action.payload.user?.userRoleDto?.accessList || [],
        verification:
          action.payload?.multiFactorAuthentication ||
          action.payload.user?.multiFactorAuthentication ||
          false,
      };

    case types.GET_ME_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_ME:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_ME_RESULT:
      return {
        ...state,
        isLoading: false,
        role: action.payload.type,
        accessList: action.payload.userRoleDto?.accessList,
      };

    case types.GET_ME_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case types.CHANGE_LOGIN_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
      };

    case types.LOGOUT_USER_ERROR:
      return {
        ...state,
        isLoggedIn: !!getAccessToken() || false,
      };

    case types.RESET_LOGIN_STORE:
      return {
        ...state,
        ...initialState,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default loginReducer;
