import * as types from "../actions/cateringAction";

const initialState = {
  data: [],
  limit: "20",
  page: 0,
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  locationNum: null,
  sortBy: "beginTime",
  orderDirection: "DESC",
  loading: false,
  scrollLoading: false,
  loadingDetails: false,
  orders: [],
  cursorDirection: "FIRST",
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
};

const cateringReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CATERING_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CATERING_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_CATERING:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_CATERING_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data:
          action.params.cursorDirection === "NEXT"
            ? [...state.data, ...action.payload.content]
            : action.payload.content,
      };

    case types.GET_CATERING_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        existNext: false,
        data: [],
      };

    case types.GET_CATERING_DETAILS:
      return {
        ...state,
        loadingDetails: true,
      };

    case types.GET_CATERING_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        orders: [
          ...state.orders.filter((order) => order.uuid !== action.payload.uuid),
          action.payload,
        ],
      };

    case types.GET_CATERING_DETAILS_ERROR:
      return {
        ...state,
        loadingDetails: false,
      };

    case types.RESET_CATERING_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default cateringReducer;
