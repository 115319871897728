import React, { useState } from "react";
import { createPortal } from "react-dom";

import cx from "classnames";

import { ReactComponent as ArrowDropDown } from "../../../assets/img/icons/common/ArrowDropDown.svg";
import { ReactComponent as Search } from "../../../assets/img/icons/common/Search.svg";

import { usePlacement } from "../../../hooks/usePlacement";
import { useHandleOutsideClick } from "../../../hooks/useHandleOutsideClick";

import "./FormSelect.scss";

export const FormSelect = ({
  title,
  name,
  options,
  value,
  disabled,
  searchable,
  onChange,
  placementSettings,
}) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const { refs, floatingStyles } = usePlacement(
    "bottom-start",
    selectOpened,
    placementSettings
  );

  useHandleOutsideClick(
    selectOpened,
    () => {
      setSelectOpened(false);
    },
    refs.floating,
    refs.reference
  );

  const handleSelectOption = (val) => {
    if (name) {
      onChange(name, val);
    } else onChange(val);
    setSelectOpened(false);
  };

  const dropdownMenu = (
    <div
      ref={refs.setFloating}
      style={floatingStyles}
      className="filter__dropdown-menu"
    >
      {searchable && (
        <div className="filter__dropdown-menu__search">
          <Search />
          <input
            type="text"
            placeholder="Search"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
        </div>
      )}
      <ul className={cx("filter__dropdown-menu__options", { searchable })}>
        {options
          .filter((option) =>
            option.label.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((option) => {
            const optionValue = option.value;

            return (
              <li
                key={optionValue}
                className={cx("option", { active: value === optionValue })}
                onClick={() => handleSelectOption(optionValue)}
              >
                <span className="option__label">{option.label}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div
      ref={refs.setReference}
      className={cx("form-select-filter", { opened: selectOpened, disabled })}
    >
      <div
        className="filter__toggle"
        onClick={() => {
          !disabled && setSelectOpened((prev) => !prev);
        }}
      >
        <div className="filter__label-container">
          <span
            className={cx("filter__title", {
              selected: value !== null && !!value && value !== "select",
            })}
          >
            {title}
          </span>
          {value && value !== "select" && (
            <span className="filter__label">
              {options.find((option) => option.value === value)
                ? options.find((option) => option.value === value).label
                : ""}
            </span>
          )}
        </div>
        <ArrowDropDown />
      </div>

      {selectOpened &&
        createPortal(dropdownMenu, document.getElementById("root"))}
    </div>
  );
};
