import cx from "classnames";
import { BodyCell } from "../../Cells";
import { TableWithPagination } from "../../../TableWithPagination";

export const DesktopTableContent = ({ rows }) => {
  const handleClick = (e, cb) => {
    if (cb) {
      cb(e);
    }
  };

  return rows.map((row, index) => (
    <>
      <tr
        key={row.id || index}
        className={cx("custom-table__tbody-row", {
          selectable: row.onSelectClick,
          selected: row.selected,
        })}
        style={{ height: row.minHeight || "auto" }}
        onClick={(e) => handleClick(e, row.onSelectClick)}
      >
        {row.cells.map((cell, index) => (
          <BodyCell key={`${row.id}-${index}`} cell={cell} />
        ))}
      </tr>
      {row.isRowOpen && (
        <tr>
          <td colSpan={row.cells.length}>
            <TableWithPagination
              className={"custom-table__inner-table"}
              rows={row.rowTableModel.rows}
              headerOptions={row.rowTableModel.headerOptions}
              isLoading={row.rowTableModel.isLoading}
              emptyText={row.rowTableModel.emptyText || "No Data"}
            />
          </td>
        </tr>
      )}
    </>
  ));
};
