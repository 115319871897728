import React from "react";

import "./HeaderToolButton.scss";

export const HeaderToolButton = ({ children, disabled, onClick }) => {
  return (
    <button
      className="header-tool-button"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
