import * as types from "../actions/supportHistoryAction";

const initialState = {
  totalResults: 0,
  page: 0,
  limit: "20",
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  timezone: null,
  restaurantUuid: null,
  userUuid: null,
  orderDirection: "DESC",
  sortBy: "requestTime",
  search: null,
  data: [],
  loading: false,
};

const supportHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SUPPORT_HISTORY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_SUPPORT_HISTORY_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.SET_SUPPORT_HISTORY_ATTACHMENT:
      return {
        ...state,
        data: state.data.map((item) =>
          item.uuid === action.payload.reportUuid
            ? {
                ...item,
                attachments: item.attachments.map((attachment, index) =>
                  index === action.payload.index
                    ? {
                        ...attachment,
                        src: action.payload.src,
                        loading: false,
                        error: !action.payload.success,
                      }
                    : attachment
                ),
              }
            : item
        ),
      };

    case types.GET_SUPPORT_HISTORY:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_SUPPORT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content.map((report) => ({
          ...report,
          attachments: report.attachments.map((attachment) => ({
            ...attachment,
            src: null,
            loading: true,
            error: false,
          })),
        })),
        errorMessage: action.message,
      };

    case types.GET_SUPPORT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_SUPPORT_HISTORY_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default supportHistoryReducer;
