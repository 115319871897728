import { ReactComponent as Close } from "../../assets/img/icons/common/Close.svg";

import "./Modal.scss";

export const DeleteSmth = ({
  title,
  span,
  id,
  the = false,
  opened,
  onConfirm,
}) => {
  return (
    <div
      className="modal fade show"
      id="ModalPassword"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ModalPasswordLabel"
      aria-hidden="true"
    >
      <div
        role="document"
        className="modal-dialog modal-dialog-centered modal__delete modal__delete-sm"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Delete {title}</h4>
            <div className="modal-close__button" onClick={() => opened(false)}>
              <Close />
            </div>
          </div>
          <div className="modal-body">
            Do you want to delete {the ? "the " : ""}
            {title.toLowerCase()}
            {span && (
              <>
                {" "}
                <span className="delete__value">{span}</span>
              </>
            )}
            ?
          </div>
          <div className="modal-footer">
            <div className="modal-button__container">
              <button
                type="button"
                className="modal-button modal-button__cancel"
                onClick={() => opened("")}
              >
                Cancel
              </button>
              <button
                className="modal-button modal-button__confirm modal-button__confirm-delete"
                type="submit"
                onClick={() => onConfirm(id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
