import { getHistory, getCsvExport, getAnalytics } from "../../api/history";
import { downloadFile } from "../../utils";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_HISTORY_VALUE = "CHANGE_HISTORY_VALUE";
export const CHANGE_HISTORY_SEVERAL_VALUES = "CHANGE_HISTORY_SEVERAL_VALUES";
export const RESET_HISTORY_STORE = "RESET_HISTORY_STORE";

export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_ERROR = "GET_HISTORY_ERROR";

export const LOAD_HISTORY_FILE = "LOAD_HISTORY_FILE";
export const LOAD_HISTORY_FILE_SUCCESS = "LOAD_HISTORY_FILE_SUCCESS";
export const LOAD_HISTORY_FILE_ERROR = "LOAD_HISTORY_FILE_ERROR";

export const GET_ORDERS_SMALL_ANALYTIC = "GET_ORDERS_SMALL_ANALYTIC";
export const GET_ORDERS_SMALL_ANALYTIC_SUCCESS =
  "GET_ORDERS_SMALL_ANALYTIC_SUCCESS";
export const GET_ORDERS_SMALL_ANALYTIC_ERROR =
  "GET_ORDERS_SMALL_ANALYTIC_ERROR";

export const getHistoryAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_HISTORY });
      try {
        const response = await getHistory(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_HISTORY_SUCCESS, payload: results });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_HISTORY_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getOrdersAnalyticAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ORDERS_SMALL_ANALYTIC });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({
            type: GET_ORDERS_SMALL_ANALYTIC_SUCCESS,
            payload: results,
          });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_ORDERS_SMALL_ANALYTIC_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const downloadHistoryFile = (params) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_HISTORY_FILE });
    try {
      const response = await getCsvExport(params);
      const type = "application/zip";
      const { success, results } = response;
      if (success) {
        downloadFile(results, type);
        dispatch({ type: LOAD_HISTORY_FILE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_HISTORY_FILE_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_HISTORY_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_HISTORY_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_HISTORY_STORE,
});
