import * as types from "../actions/recDoneAction";

const initialState = {
  data: [],
  limit: "20",
  page: 0,
  totalResults: 0,
  loading: false,
  uuid: "",
  sortBy: "startTime",
  orderDirection: "DESC",
  startDate: "select",
  endDate: "select",
  timeInterval: null,
  intervalEnd: null,
  isFileLoading: false,
};

const recDoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_RECOMMENDATION_DONE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_RECOMMENDATION_DONE_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_RECOMMENDATION_DONE:
      return {
        ...state,
        loading: true,
      };

    case types.GET_RECOMMENDATION_DONE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.content,
        totalResults: action.payload.totalElements,
      };

    case types.GET_RECOMMENDATION_DONE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.LOAD_REC_DONE_FILE:
      return {
        ...state,
        isFileLoading: true,
      };

    case types.LOAD_REC_DONE_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.LOAD_REC_DONE_FILE_ERROR:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.RESET_RECOMMENDATION_DONE_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default recDoneReducer;
