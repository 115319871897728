import { ReactComponent as AuthLog } from "../assets/img/icons/userSettings/authLog.svg";
import { ReactComponent as Sessions } from "../assets/img/icons/userSettings/sessions.svg";

import { ReactComponent as Settings } from "../assets/img/icons/sidebar/Settings.svg";
import { ReactComponent as Transactions } from "../assets/img/icons/sidebar/Transactions.svg";
import { ReactComponent as OrderHistory } from "../assets/img/icons/sidebar/OrderHistory.svg";
import { ReactComponent as RecommendationHistory } from "../assets/img/icons/sidebar/RecommendationHistory.svg";
import { ReactComponent as DLPS } from "../assets/img/icons/sidebar/DLPS.svg";
import { ReactComponent as Pins } from "../assets/img/icons/sidebar/Pins.svg";
import { ReactComponent as VTMMessages } from "../assets/img/icons/sidebar/VTMMessages.svg";

import * as R from "./routes.constants";

export const pages = [
  {
    link: R.SETTINGS,
    i: "fa fa-cog",
    Icon: Settings,
    span: "Settings",
    disabled: false,
  },
  {
    link: R.TRANSACTIONS_INFO,
    i: "fas fa-list-alt",
    Icon: Transactions,
    span: "Transactions",
    disabled: false,
    tooltip: [
      {
        link: R.TRANSACTIONS_INFO,
        i: "",
        span: "History",
        disabled: false,
      },
      {
        link: R.CATERING,
        i: "",
        span: "Catering",
        disabled: false,
      },
      {
        link: R.TRANSACTIONS_ANALYTIC,
        i: "",
        span: "Analytics",
        disabled: false,
      },
    ],
  },
  {
    link: R.ORDERS_HISTORY,
    i: "fas fa-history",
    Icon: OrderHistory,
    span: "Orders",
    disabled: false,
    tooltip: [
      {
        link: R.ORDERS_HISTORY,
        i: "",
        span: "History",
        disabled: false,
      },
      {
        link: R.ORDERS_ANALYTICS,
        i: "",
        span: "Analytics",
        disabled: false,
      },
    ],
  },
  {
    link: R.REC_HISTORY,
    i: "fas fa-history",
    Icon: RecommendationHistory,
    span: "Recommendations",
    disabled: false,
  },
  {
    link: R.DLPS,
    i: "fa fa-server",
    Icon: DLPS,
    span: "DLPS",
    disabled: false,
  },
  {
    link: R.PINS,
    i: "fas fa-map-pin",
    Icon: Pins,
    span: "Pins",
    disabled: false,
  },
  {
    link: R.QUESTIONS,
    i: "fa fa-envelope",
    Icon: VTMMessages,
    span: "VTM Messages",
    disabled: false,
  },
];

export const userSidebar = [
  {
    link: R.AUTHENTIFICATION_LOG,
    i: <AuthLog />,
    span: "Authentication Log",
    disabled: false,
  },
  {
    link: R.ACTIVE_SESSIONS,
    i: <Sessions />,
    span: "Active Sessions",
    disabled: false,
  },
];

export const accessNames = [
  { module: "KPS_SETTINGS", pages: [R.SETTINGS] },
  { module: "VTM_MESSAGES", pages: R.QUESTIONS },
  {
    module: "TRANSACTIONS",
    pages: [R.TRANSACTIONS_INFO, R.CATERING, R.TRANSACTIONS_ANALYTIC],
  },
  { module: "ORDERS", pages: [R.ORDERS_HISTORY, R.ORDERS_ANALYTICS] },
  { module: "RECOMMENDATIONS", pages: [R.REC_HISTORY] },
  { module: "DLPS", pages: [R.DLPS] },
  { module: "PINS", pages: R.PINS },
  { module: "SUPPORT", pages: [R.SUPPORT_HISTORY] },
];
