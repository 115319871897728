import {
  getQuestions,
  addQuestion,
  deleteQuestion,
  answerQuestion,
} from "../../api/questions";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_QUESTIONS_VALUE = "CHANGE_QUESTIONS_VALUE";
export const CHANGE_QUESTIONS_SEVERAL_VALUES =
  "CHANGE_QUESTIONS_SEVERAL_VALUES";

export const RESET_QUESTIONS_STORE = "RESET_QUESTIONS_STORE";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_ERROR = "GET_QUESTIONS_ERROR";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_ERROR = "DELETE_QUESTION_ERROR";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_ERROR = "ADD_QUESTION_ERROR";

export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_ERROR = "ANSWER_QUESTION_ERROR";

export const getQuestionsAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_QUESTIONS });
      try {
        const response = await getQuestions(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_QUESTIONS_SUCCESS, payload: results });
          actions.onSuccess && actions.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_QUESTIONS_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const deleteQuestionAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_QUESTION });
    try {
      const response = await deleteQuestion(uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: DELETE_QUESTION_SUCCESS });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_QUESTION_ERROR });
      actions.onError && actions.onError(error.message);
    }
  };
};

export const addQuestionAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: ADD_QUESTION });
    try {
      const response = await addQuestion(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: ADD_QUESTION_SUCCESS });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ADD_QUESTION_ERROR });
      actions.onError && actions.onError(error.message);
    }
  };
};

export const answerQuestionAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: ANSWER_QUESTION });
    try {
      const response = await answerQuestion(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: ANSWER_QUESTION_SUCCESS });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ANSWER_QUESTION_ERROR });
      actions.onError && actions.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_QUESTIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_QUESTIONS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_QUESTIONS_STORE,
});
