import {
  getRestaurants,
  getTransactionAnalytics,
  getTransactionDetails,
  getTransactions,
} from "../../api/transactions";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_TRANSACTIONS_VALUE = "CHANGE_TRANSACTIONS_VALUE";
export const CHANGE_TRANSACTIONS_SEVERAL_VALUES =
  "CHANGE_TRANSACTIONS_SEVERAL_VALUES";
export const RESET_TRANSACTIONS_STORE = "RESET_TRANSACTIONS_STORE";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR";

export const GET_TRANSACTIONS_RESTAURANTS = "GET_TRANSACTIONS_RESTAURANTS";
export const GET_TRANSACTIONS_RESTAURANTS_SUCCESS =
  "GET_TRANSACTIONS_RESTAURANTS_SUCCESS";
export const GET_TRANSACTIONS_RESTAURANTS_ERROR =
  "GET_TRANSACTIONS_RESTAURANTS_ERROR";

export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_ERROR = "GET_TRANSACTION_DETAILS_ERROR";

export const GET_TRANSACTIONS_SMALL_ANALYTIC =
  "GET_TRANSACTIONS_SMALL_ANALYTIC";
export const GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS =
  "GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS";
export const GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR =
  "GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR";

export const getTransactionsAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({
        type: GET_TRANSACTIONS,
        payload: params.cursorDirection === "FIRST",
      });
      try {
        const response = await getTransactions(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({
            type: GET_TRANSACTIONS_SUCCESS,
            payload: results,
            params,
          });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_TRANSACTIONS_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getRestaurantsAction = (setData, withoutSelect = false) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS_RESTAURANTS,
    });
    try {
      const response = await getRestaurants();
      const { success, results } = response;
      if (success) {
        setData([
          ...(!withoutSelect
            ? [{ label: "Select Location Number", value: "select" }]
            : []),
          ...results.map((res) => ({ label: res, value: res })),
        ]);
        dispatch({
          type: GET_TRANSACTIONS_RESTAURANTS_SUCCESS,
          payload: results,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTIONS_RESTAURANTS_ERROR });
    }
  };
};

export const getTransactionsAnalyticAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({
        type: GET_TRANSACTIONS_SMALL_ANALYTIC,
      });
      try {
        const response = await getTransactionAnalytics(
          params,
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({
            type: GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS,
            payload: results,
            params,
          });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getTransactionDetailsAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_TRANSACTION_DETAILS });
    try {
      const response = await getTransactionDetails(uuid, params);
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_TRANSACTION_DETAILS_SUCCESS,
          payload: results,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTION_DETAILS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TRANSACTIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_TRANSACTIONS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_TRANSACTIONS_STORE,
});
