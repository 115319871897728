import * as ActionTypes from "../actions/getSupportAction";

const initialState = {
  restaurantUuid: "select",
  problems: [],
  problem: "",
  problemMessage: "",
  another: "",
  attachmentFiles: [],
  attachments: [],
  isLoading: false,
};

const getSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEND_SUPPORT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.SEND_SUPPORT_RESULT:
    case ActionTypes.SEND_SUPPORT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CHANGE_PLAYER_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case ActionTypes.GET_SUPPORT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_SUPPORT_RESULT:
      return {
        ...state,
        problems: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_SUPPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case ActionTypes.ADD_SUPPORT_ATTACHMENT:
      return {
        ...state,
        attachments: [action.payload.file, ...state.attachments],
      };

    case ActionTypes.EDIT_SUPPORT_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.map((attachment) => {
          if (attachment.id === action.payload.id) {
            return {
              ...attachment,
              [action.payload.key]: action.payload.value,
            };
          } else {
            return attachment;
          }
        }),
      };

    case ActionTypes.DELETE_SUPPORT_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(
          (attachment) => attachment.id !== action.payload.id
        ),
      };

    case ActionTypes.RESET_SUPPORT_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default getSupportReducer;
