import React from "react";

import { formatStatus } from "../../utils/formatting";

import { defaultStatusOuterColors, defaultStatusTextColors } from "./constants";

import "./StatusTag.scss";

export const StatusTag = ({
  status,
  textColors = defaultStatusTextColors,
  outerColors = defaultStatusOuterColors,
  customFormatting = false,
}) => {
  return (
    <div
      className="status-tag"
      style={{
        color: textColors[status.toLowerCase()] || "#1A1A1A",
        backgroundColor: outerColors[status.toLowerCase()] || "transparent",
      }}
    >
      {customFormatting ? formatStatus(status) : status}
    </div>
  );
};
