import { isEmpty } from "lodash";

import { InfoRow } from "./InfoRow";

export const TableContentContainer = ({
  isLoading,
  rows,
  children,
  emptyText,
  isMobile,
}) => {
  const getContent = () => {
    if (isLoading) {
      return (
        <InfoRow>
          <i className="fa fa-spinner fa-spin fa-2x" />
        </InfoRow>
      );
    }

    if (isEmpty(rows)) {
      return (
        <InfoRow>
          <span className="no-info">
            {emptyText ? emptyText : "No records"}
          </span>
        </InfoRow>
      );
    }

    return children;
  };

  return (
    <tbody className={isMobile ? "mobile-table__tbody" : "custom-table__tbody"}>
      {getContent()}
    </tbody>
  );
};
