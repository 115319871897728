import * as types from "../actions/transactionsAction";

const initialState = {
  data: [],
  limit: "20",
  page: 0,
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  locationNum: null,
  sortBy: "beginTime",
  orderDirection: "DESC",
  loading: false,
  scrollLoading: false,
  loadingDetails: false,
  orders: [],
  cursorDirection: "FIRST",
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  chartLoading: false,
  graphContent: [],
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TRANSACTIONS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_TRANSACTIONS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_TRANSACTIONS:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data:
          action.params.cursorDirection === "NEXT"
            ? [...state.data, ...action.payload.content]
            : action.payload.content,
      };

    case types.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        existNext: false,
        data: [],
      };

    case types.GET_TRANSACTIONS_SMALL_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
      };

    case types.GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        graphContent:
          Object.keys(action.payload).length !== 0
            ? Object.keys(action.payload).map((key) => [
                new Date(
                  new Date(key).getTime() -
                    new Date().getTimezoneOffset() * 60000
                ),
                action.payload[key],
              ])
            : [0, 0],
      };

    case types.GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        loadingDetails: true,
      };

    case types.GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        orders: [
          ...state.orders.filter((order) => order.uuid !== action.payload.uuid),
          action.payload,
        ],
      };

    case types.GET_TRANSACTION_DETAILS_ERROR:
      return {
        ...state,
        loadingDetails: false,
      };

    case types.RESET_TRANSACTIONS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default transactionsReducer;
