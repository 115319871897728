import React from "react";

import { ReactComponent as Copy } from "../../assets/img/icons/common/Copy.svg";

import { TablePreloader } from "../Preloader/TablePreloader";
import { LargeIconButton } from "../Buttons";

import "./CodePreview.scss";

export const CodePreview = ({ data, isLoading }) => {
  if (isLoading) {
    return <TablePreloader big={true} />;
  }

  if (!data) return null;

  return (
    <div className="code-preview__container">
      <LargeIconButton
        className="code-preview__copy-btn"
        Icon={Copy}
        type={"gray"}
        onClick={() =>
          navigator.clipboard.writeText(JSON.stringify(data, undefined, 4))
        }
      />

      <pre className="code-preview">
        <code>{JSON.stringify(data, undefined, 4)}</code>
      </pre>
    </div>
  );
};
