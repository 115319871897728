import request from "../utils/request";

export function getDoneRecommendations(params, signal) {
  return request({
    url: "/recommendation/list/done",
    method: "get",
    data: params,
    signal,
  });
}

export function getCsvExport(params) {
  return request({
    url: "/recommendation/csv",
    method: "get",
    data: params,
  });
}
