import React from "react";

import "./HeaderToolLabelValue.scss";

export const HeaderToolLabelValue = ({ label, value }) => {
  return (
    <div className="header-tool-labeled-value">
      <span className="header-tool-labeled-value__label">{label}</span>
      <span className="header-tool-labeled-value__value">{value}</span>
    </div>
  );
};
