import { getCatering, getTransactionDetails } from "../../api/transactions";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_CATERING_VALUE = "CHANGE_CATERING_VALUE";
export const CHANGE_CATERING_SEVERAL_VALUES = "CHANGE_CATERING_SEVERAL_VALUES";
export const RESET_CATERING_STORE = "RESET_CATERING_STORE";

export const GET_CATERING = "GET_CATERING";
export const GET_CATERING_SUCCESS = "GET_CATERING_SUCCESS";
export const GET_CATERING_ERROR = "GET_CATERING_ERROR";

export const GET_CATERING_DETAILS = "GET_CATERING_DETAILS";
export const GET_CATERING_DETAILS_SUCCESS = "GET_CATERING_DETAILS_SUCCESS";
export const GET_CATERING_DETAILS_ERROR = "GET_CATERING_DETAILS_ERROR";

export const getCateringAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({
        type: GET_CATERING,
        payload: params.cursorDirection === "FIRST",
      });
      try {
        const response = await getCatering(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_CATERING_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_CATERING_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getCateringDetailsAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_CATERING_DETAILS });
    try {
      const response = await getTransactionDetails(uuid, params);
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_CATERING_DETAILS_SUCCESS,
          payload: results,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CATERING_DETAILS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CATERING_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CATERING_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_CATERING_STORE,
});
