import _ from "lodash";
import axios from "axios";
import { parse, compile } from "path-to-regexp";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { refreshToken } from "../redux/actions/loginAction";
import store from "../redux/store";

import { getAccessToken, getAuthDataFromLocalStorage } from "./authorization";

const until = (conditionFunc) => {
  const poll = (resolve) => {
    if (conditionFunc()) resolve();
    else setTimeout((_) => poll(resolve), 100);
  };

  return new Promise(poll);
};

axios.defaults.baseURL = process.env.REACT_APP_URL_API;
axios.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();

    if (localStorage.getItem("isRefreshing")) {
      await until(() => !localStorage.getItem("isRefreshing"));
    }

    if (config.url === "/auth/refresh" && config.method === "post") {
      localStorage.setItem("isRefreshing", true);
      const authData = getAuthDataFromLocalStorage();
      const refreshToken = authData.refreshToken;
      config.data = { refreshToken };
    }

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(axios, () => store.dispatch(refreshToken()));

const fetch = (options) => {
  let {
    method = "get",
    data,
    url,
    headers,
    responseType,
    withCredentials = false,
    skipAuthRefresh = false,
    signal,
  } = options;
  headers = headers || {};

  const cloneData = data instanceof FormData ? data : _.cloneDeep(data);

  try {
    let domin = "";
    if (
      url.match(/[a-zA-z]+:\/\/[^/]*/) &&
      url.match(/[a-zA-z]+:\/\/[^/]*/) === process.env.REACT_APP_URL_API
    ) {
      [domin] = url.match(/[a-zA-z]+:\/\/[^/]*/);
      url = url.slice(domin.length);
    }
    const match = parse(url);
    url = compile(url)(data);
    for (let item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name];
      }
    }
    url = domin + url;
  } catch (e) {
    console.log(e.message);
  }

  switch (method.toLowerCase()) {
    case "get":
      return axios.get(url, {
        params: cloneData,
        headers: headers,
        responseType: responseType,
        signal,
      });
    case "delete":
      return axios.delete(url, {
        data: cloneData,
      });
    case "post":
      if (withCredentials) {
        return axios.post(url, cloneData, { headers, withCredentials });
      } else {
        return axios.post(url, cloneData, { headers, skipAuthRefresh });
      }
    case "put":
      return axios.put(url, cloneData);
    case "patch":
      return axios.patch(url, cloneData);
    default:
      return axios(options);
  }
};

export default function request(options) {
  return fetch(options)
    .then((response) => {
      const { statusText, status, data } = response;
      return Promise.resolve({
        success: true,
        message: statusText,
        code: status,
        results: data,
      });
    })
    .catch((error) => {
      const { response } = error;
      let code;
      let message;
      if (response && response instanceof Object) {
        const { data, statusText, status } = response;
        code = status;
        message = data.message || statusText;
      } else {
        code = 600;
        message = error.message || "Network error";
      }

      return Promise.reject({ success: false, code, message: message });
    });
}
