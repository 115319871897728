import React, { useState } from "react";

import { ReactComponent as Password } from "../../assets/img/icons/userSettings/password.svg";

import ChangePasswordModal from "../Modal/ChangePasswordModal";

import "./UserSidebar.scss";

export const UserSidebar = ({
  login,
  items,
  addNotification,
  currentItem,
  onItemClick = false,
}) => {
  const [changePassword, setChangePassword] = useState(
    login.changePasswordClicked || false
  );

  return (
    <>
      <ul className="user-settings--sidebar">
        {items.map((item, i) => (
          <li
            key={i}
            className={`user-settings--sidebar-item ${
              item.span === currentItem ? "active" : ""
            }`}
            onClick={() => onItemClick(item.link)}
          >
            {item.i}
            <p>{item.span}</p>
          </li>
        ))}
        <li
          className={`user-settings--sidebar-item`}
          onClick={() => setChangePassword(true)}
        >
          <Password />
          <p>Change Password</p>
        </li>
      </ul>
      {changePassword && (
        <ChangePasswordModal
          opened={changePassword}
          addNotification={addNotification}
          setOpened={(value) => setChangePassword(value)}
        />
      )}
    </>
  );
};
