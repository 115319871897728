import * as types from "../actions/authLogAction";

const initialState = {
  data: [],
  limit: "20",
  page: 0,
  totalResults: 0,
  loading: false,
};

const authLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_AUTH_LOG_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.GET_AUTH_LOG:
      return {
        ...state,
        loading: true,
      };

    case types.GET_AUTH_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.content,
        totalResults: action.payload.totalElements,
      };

    case types.GET_AUTH_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_AUTH_LOG_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default authLogReducer;
