import { getAnalytics } from "../../api/history";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_ORDERS_ANALYTIC_VALUE = "CHANGE_ORDERS_ANALYTIC_VALUE";
export const CHANGE_ORDERS_ANALYTIC_SEVERAL_VALUES =
  "CHANGE_ORDERS_ANALYTIC_SEVERAL_VALUES";

export const RESET_ORDERS_ANALYTIC_STORE = "RESET_ORDERS_ANALYTIC_STORE";

export const GET_ORDERS_ANALYTIC = "GET_ORDERS_ANALYTIC";
export const GET_ORDERS_ANALYTIC_SUCCESS = "GET_ORDERS_ANALYTIC_SUCCESS";
export const GET_ORDERS_ANALYTIC_ERROR = "GET_ORDERS_ANALYTIC_ERROR";

export const getOrdersAnalyticAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ORDERS_ANALYTIC });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_ORDERS_ANALYTIC_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_ORDERS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_ORDERS_ANALYTIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_ORDERS_ANALYTIC_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_ORDERS_ANALYTIC_STORE,
});
