import React, { Suspense, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import "animate.css/animate.min.css";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { MainSidebar, NotificationContent, PageLoader } from "./components";
import Header from "./components/NewHeader/Header";

import {
  setCurrentPage,
  setSidebar,
  changeScreen,
} from "./redux/actions/appAction";
import {
  changeValue,
  getMeAction,
  resetUser,
  refreshToken,
} from "./redux/actions/loginAction";

import * as authUtils from "./utils/authorization";
import { checkScreenWidth } from "./utils/resizing";
import { accessNames, pages, userSidebar } from "./constants/sidebar.constants";
import routes from "./routes";

function App({
  app,
  login,
  isLoggedIn,
  setSidebar,
  getMeAction,
  changeValue,
  setCurrentPage,
  resetUser,
  refreshToken,
  changeScreen,
}) {
  const history = useHistory();
  const [showMenu, toggleShowMenu] = useState(true);

  const updateTokensTimer = useRef(null);

  useEffect(() => {
    localStorage.removeItem("isRefreshing");
    setCurrentPage(
      typeof [...pages, ...userSidebar].find((page) =>
        window.location.pathname.includes(page.link.replace("*", ""))
      ) !== "undefined"
        ? [...pages, ...userSidebar].find((page) =>
            window.location.pathname.includes(page.link.replace("*", ""))
          ).span
        : "Settings"
    );

    const handleStorageEvent = (event) => {
      authUtils.checkStorageChanges(event, {
        resetUser,
      });
    };
    window.addEventListener("storage", handleStorageEvent);

    checkScreen();
    window.addEventListener("resize", checkScreen);

    return () => {
      localStorage.removeItem("isRefreshing");
      clearInterval(updateTokensTimer.current);
      window.removeEventListener("storage", handleStorageEvent);
      window.removeEventListener("resize", checkScreen);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && login.verified) {
      getMeAction({
        onSuccess: () => {
          checkExpireTokens();
        },
      });
      updateTokensTimer.current = setInterval(
        checkExpireTokens,
        (process.env.REACT_APP_TIME_UNTIL_TOKEN_EXPIRES / 3) * 1000
      );
    } else {
      toggleShowMenu(false);
    }

    return () => {
      clearInterval(updateTokensTimer.current);
    };
  }, [isLoggedIn, login.verified]);

  useEffect(() => {
    if (login.role && login.role !== "ADMIN") {
      let modules = login.accessList.map((item) => item.moduleType);
      changeValue(
        "pages",
        accessNames
          .map((name) => (modules.includes(name.module) ? name.pages : []))
          .flat()
      );
    }
  }, [login.role]);

  useEffect(() => {
    [
      "Set New Password",
      "Expired Token",
      "Multi Factor Auth",
      "Login",
      "Change Expired Password",
      "Blocked Account",
    ].includes(app.currentPage)
      ? toggleShowMenu(false)
      : isLoggedIn && !login.isLoading && toggleShowMenu(true);
  }, [app.currentPage, isLoggedIn, login.isLoading]);

  const checkExpireTokens = () => {
    const isRefreshing = localStorage.getItem("isRefreshing");
    if (isRefreshing) return;

    const authData = authUtils.getAuthDataFromLocalStorage();
    if (
      authData &&
      authData.refreshToken &&
      authData.accessExpDate &&
      authData.refreshExpDate
    ) {
      const { accessExpDate, refreshExpDate } = authData;
      if (
        new Date(accessExpDate) - new Date() <
          process.env.REACT_APP_TIME_UNTIL_TOKEN_EXPIRES * 1000 &&
        new Date(refreshExpDate) - new Date() > 0
      ) {
        refreshToken();
      }
    }
  };

  const createNotification = (type, text, title, time = 3000, customProps) => {
    const notificationId = Store.addNotification({
      content: (
        <NotificationContent
          type={type}
          title={title}
          text={text}
          customProps={customProps}
        />
      ),
      message: text,
      type: type,
      insert: "top",
      container: "top-right",
      timingFunction: "ease-out",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        pauseOnHover: true,
        touch: false,
        onScreen: true,
        duration: time,
        showIcon: true,
      },
      onRemoval: (id) => Store.removeNotification(id),
    });

    setTimeout(() => Store.removeNotification(notificationId), time);
  };

  const checkScreen = () => {
    let width = checkScreenWidth();
    changeScreen(width);
  };

  return (
    <BrowserRouter history={history}>
      <div className={`${showMenu ? "page-content" : ""}`}>
        <Header
          history={history}
          showMenu={showMenu}
          sidebarToggle={() => setSidebar(!app.sidebar)}
        />
        <div className="page-content__body">
          {showMenu && !login.isLoading && (
            <MainSidebar
              login={login}
              isOpen={app.sidebar}
              items={
                !login.role
                  ? []
                  : login.role !== "ADMIN"
                  ? pages.filter((page) => login.pages.includes(page.link))
                  : pages
              }
              currentItem={app.currentPage}
              setSidebar={setSidebar}
            />
          )}
          <Suspense fallback={<PageLoader />}>
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  exact
                  history={history}
                  render={(props) => (
                    <route.component
                      {...props}
                      isLoggedIn={isLoggedIn}
                      sidebar={app.sidebar}
                      currentPage={app.currentPage}
                      setSidebar={setSidebar}
                      setCurrentPage={setCurrentPage}
                      addNotification={createNotification}
                    />
                  )}
                />
              ))}
              <Redirect from="/" to="/settings" />
            </Switch>
          </Suspense>
        </div>
        <ReactNotifications />
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  app: state.app,
  login: state.login,
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    setSidebar: (isOpen) => dispatch(setSidebar(isOpen)),
    getMeAction: (actions) => dispatch(getMeAction(actions)),
    resetUser: () => dispatch(resetUser()),
    refreshToken: () => dispatch(refreshToken()),
    changeValue: (key, value) => dispatch(changeValue(key, value)),
    changeScreen: (value) => dispatch(changeScreen(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
