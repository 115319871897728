import request from "../utils/request";

export function getTransactions(params, signal) {
  return request({
    url: "/transactions/list",
    method: "get",
    data: params,
    signal,
  });
}

export function getRestaurants() {
  return request({
    url: "/transactions/restaurants",
    method: "get",
  });
}

export function getCatering(params, signal) {
  return request({
    url: "/transactions/list/catering",
    method: "get",
    data: params,
    signal,
  });
}

export function getTransactionDetails(uuid, params) {
  return request({
    url: `/transactions/${uuid}`,
    method: "get",
    data: params,
  });
}

export function getTransactionAnalytics(params, signal) {
  return request({
    url: `/transactions/analytic`,
    method: "get",
    data: params,
    signal,
  });
}
