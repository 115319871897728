import * as types from "../actions/historyAction";

const initialState = {
  data: [],
  limit: "20",
  page: 0,
  totalResults: 0,
  loading: false,
  locationNum: null,
  sortBy: "eventTime",
  orderDirection: "DESC",
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  isFileLoading: false,
  chartLoading: false,
  graphContent: [],
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_HISTORY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_HISTORY_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case types.GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.content,
        totalResults: action.payload.totalElements,
      };

    case types.GET_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.GET_ORDERS_SMALL_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
        graphContent: [],
      };

    case types.GET_ORDERS_SMALL_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        graphContent:
          action.payload.length !== 0
            ? action.payload[0].values.map((item, index) => [
                new Date(item[0] * 1000),
                action.payload.reduce(function (summ, elem) {
                  return (
                    summ +
                    Object.values(elem.values[index][1]).reduce(function (
                      sum,
                      elem
                    ) {
                      return sum + Number(elem);
                    },
                    0)
                  );
                }, 0),
              ])
            : [[0, 0]],
      };

    case types.GET_ORDERS_SMALL_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.LOAD_HISTORY_FILE:
      return {
        ...state,
        isFileLoading: true,
      };

    case types.LOAD_HISTORY_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.LOAD_HISTORY_FILE_ERROR:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.RESET_HISTORY_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default historyReducer;
