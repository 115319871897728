import React from "react";
import cx from "classnames";

import "./NotificationContent.scss";

export const NotificationContent = ({
  type,
  text,
  title,
  customProps = {},
}) => {
  return (
    <div
      className={cx("notification-content", `notification-content--${type}`)}
    >
      <div className="notification-content__body">
        <div className="notification-content__message">{text}</div>
        {!!customProps.time && (
          <div className="notification-content__time">{customProps.time}</div>
        )}
      </div>
      <div className="notification-content__close-mark"></div>
    </div>
  );
};
