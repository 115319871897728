import * as types from "../actions/questionAction";

const initialState = {
  data: [],
  limit: "20",
  page: 0,
  totalResults: 0,
  loading: false,
  startDate: "select",
  endDate: "select",
  timeInterval: null,
  intervalEnd: null,
  location: null,
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_QUESTIONS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_QUESTIONS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_QUESTIONS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.content,
        totalResults: action.payload.totalElements,
      };

    case types.GET_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.ADD_QUESTION_SUCCESS:
    case types.ANSWER_QUESTION_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };

    case types.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };

    case types.RESET_QUESTIONS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default questionsReducer;
