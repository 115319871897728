import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import logo from "../../assets/img/icons/header/cfa_logo_without_tm.svg";

import { ReactComponent as Menu } from "../../assets/img/icons/header/Menu.svg";
import { ReactComponent as Question } from "../../assets/img/icons/header/Question.svg";
import { ReactComponent as User } from "../../assets/img/icons/header/User.svg";

import { PageDropdown } from "../index";

import { changeValue, logoutUser } from "../../redux/actions/loginAction";

import {
  AUTHENTIFICATION_LOG,
  LOGIN,
  MULTI_FACTOR_AUTH,
  SUPPORT_HISTORY,
  ACTIVE_SESSIONS,
  SETTINGS,
} from "../../constants/routes.constants";

import "./Header.scss";

const Header = ({
  login,
  app,
  showMenu,
  logoutUser,
  sidebarToggle,
  changeValue,
}) => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (!login.verified) history.push(MULTI_FACTOR_AUTH);
  }, []);

  useEffect(() => {
    if (
      !login.isLoggedIn &&
      ![MULTI_FACTOR_AUTH].find((item) =>
        history.location.pathname.includes(item)
      )
    )
      history.push(LOGIN);
    else if (!login.verified) history.push(MULTI_FACTOR_AUTH);
    else if (
      login.isLoggedIn &&
      [MULTI_FACTOR_AUTH].find((item) =>
        history.location.pathname.includes(item)
      )
    ) {
      history.push(SETTINGS);
    }
  }, [login.isLoggedIn, app.currentPage]);

  useEffect(() => {
    !login.verified && history.push(MULTI_FACTOR_AUTH);
  }, [login.verified]);

  useEffect(() => {
    if (opened) {
      document.addEventListener("click", checkClick);
    }
  }, [opened]);

  function checkClick(e) {
    if (
      !(e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementById(`navbar_select_restaurant`)
      )
    ) {
      setOpened(false);
      return document.removeEventListener("click", checkClick);
    }
  }

  const handleLogout = () => {
    logoutUser();
    history.push(LOGIN);
  };

  return (
    showMenu && (
      <header id="app-header" className="header">
        <div className="header-main-group">
          <div
            className="header-main-group__bars header-icons-item"
            onClick={sidebarToggle}
          >
            <Menu />
          </div>
          <Link className="header-main-group__logo" to={ACTIVE_SESSIONS}>
            <img alt="logo" src={logo} />
          </Link>
        </div>
        <div className="header-icons-group">
          {login.role === "ADMIN" && (
            <div
              className="header-icons-item"
              onClick={() => history.push(SUPPORT_HISTORY)}
            >
              <Question />
            </div>
          )}
          <PageDropdown
            icon={User}
            options={[
              {
                label: "My Account",
                function: () => history.push(AUTHENTIFICATION_LOG),
              },
              { label: "Sign Out", function: () => handleLogout() },
            ]}
          />
        </div>
      </header>
    )
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  app: state.app,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    changeValue: (key, value) => dispatch(changeValue(key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
