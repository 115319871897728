import moment from "moment";
import { getTimezoneOffsetInSeconds } from "../../../utils";

import {
  googleColors,
  hAxisUnits,
} from "../../../constants/analytics.constants";
import { getChartAxis, getChartMax } from "../../../utils/chartHelper";
import { barChartOptions } from "./constants";
import { getFormattedWaste } from "../../../utils/dashboard";

export const getChartData = (
  graphContent,
  chartHeader,
  timezone,
  colored = false,
  customColors = false,
  customFormat = false,
  isShowTotal
) => {
  return [
    [
      "Timestamp",
      { type: "string", role: "tooltip", p: { html: true } },
      ...chartHeader,
    ],
    ...(graphContent.length !== 0 &&
    graphContent[0].length === chartHeader.length + 1
      ? graphContent.map((graph) => {
          let startDate = new Date(
            graph[0].getTime() + getTimezoneOffsetInSeconds(timezone)
          );
          let total = 0;
          return [
            startDate,
            `<div class="chart-tooltip">${moment(startDate).format(
              "MMM D, YYYY, HH:mm:ss"
            )}${`${graph
              .slice(1)
              .map((content, index) => [chartHeader[index], content, index])
              .sort((x, y) => {
                return y[1] - x[1];
              })
              .map((content) => {
                if (isShowTotal) {
                  total += content[1];
                }
                return `<div class="chart-tooltip__content">${
                  colored
                    ? `<div class="chart-tooltip__color" style="background-color: ${
                        customColors
                          ? customColors[content[2]]
                          : googleColors[content[2]]
                      };"></div>`
                    : ""
                }${content[0]}:&nbsp<b>${
                  customFormat ? customFormat(content[1]) : content[1]
                } </b></div>`;
              })}`.replaceAll(",", "")} ${
              isShowTotal
                ? `Total ${
                    customFormat
                      ? customFormat(getFormattedWaste(total))
                      : getFormattedWaste(total)
                  }`
                : ""
            }</div>`,
            ...graph.slice(1),
          ];
        })
      : [[0, "", ...chartHeader.map(() => 0)]]),
  ];
};

export const checkGraph = (graph) => {
  return (
    graph &&
    graph.length !== 0 &&
    graph.reduce(function (sum, elem) {
      return (
        sum +
        (Number(
          elem.slice(1).reduce(function (summ, el) {
            return summ + Number(el);
          }, 0)
        ) || 0)
      );
    }, 0) > 0
  );
};

export const getOptions = (
  graphContent,
  dates,
  mobile,
  customOptions,
  customColors
) => {
  let maxGraph = Math.max(
    ...(graphContent
      .map((item) =>
        item.slice(1).reduce(function (sum, elem) {
          return sum + Number(elem);
        }, 0)
      )
      .flat() || 100)
  );
  return {
    colors: customColors ? customColors : googleColors,
    tooltip: { isHtml: true, trigger: mobile ? "selection" : "focus" },
    hAxis: {
      allowContainerBoundaryTextCutoff: true,
      maxTextLines: 1,
      minValue: new Date(
        Math.floor(
          dates[2] !== null && dates[4]
            ? moment().tz(dates[4]).format("x") - dates[2] * 1000
            : dates[0]
        )
      ),
      gridlines: {
        color: "none",
        ...hAxisUnits,
      },
      minorGridlines: {
        count: 0,
        ...hAxisUnits,
      },
    },
    vAxis: {
      allowContainerBoundaryTextCutoff: true,
      maxTextLines: 1,
      ticks: getChartAxis(maxGraph),
      minorGridlines: {
        count: 0,
      },
      viewWindow: {
        min: 0,
        max: getChartMax(maxGraph),
      },
      minValue: 0,
      maxValue: getChartMax(maxGraph),
    },
    chartArea: {
      width: mobile ? "85%" : "94%",
      height: "75%",
      top: 8,
      right: mobile ? 12 : "auto",
    },
    ...barChartOptions,
    ...customOptions,
  };
};
