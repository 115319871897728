import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";

import { ReactComponent as ArrowDropDown } from "../../../assets/img/icons/common/ArrowDropDown.svg";
import { ReactComponent as Close } from "../../../assets/img/icons/common/Close.svg";
import { ReactComponent as Done } from "../../../assets/img/icons/common/Done.svg";
import { ReactComponent as Search } from "../../../assets/img/icons/common/Search.svg";

import {
  checkSearchParameter,
  deleteSearchParameters,
  getSearchParameter,
  setSearchParameter,
} from "../../../utils/urlFunctions";
import "./MultipleSelectFilter.scss";
import { usePlacement } from "../../../hooks/usePlacement";
import { useHandleOutsideClick } from "../../../hooks/useHandleOutsideClick";

export const MultipleSelectFilter = ({
  history,
  title,
  name,
  options,
  values,
  rightAlign = false,
  onChange,
  changedSearch,
  setChangedSearch,
  clearable = true,
  searchable = true,
  disabled,
  placement = "bottom-start",
}) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const { refs, floatingStyles } = usePlacement(placement, selectOpened);

  useHandleOutsideClick(
    selectOpened,
    () => {
      setSelectOpened(false);
    },
    refs.floating,
    refs.reference
  );

  useEffect(() => {
    if (name && history && checkSearchParameter(name) && options.length !== 0) {
      checkAddressLine();
    }
  }, [options]);

  useEffect(() => {
    if (changedSearch && options.length !== 0) {
      if (!checkSearchParameter(name) && values.length !== 0) {
        onChange([]);
      } else if (
        checkSearchParameter(name) &&
        getSearchParameter(name).split(",").length !== values.length
      ) {
        onChange(getSearchParameter(name).split(","));
      }
      setChangedSearch(false);
    }
  }, [changedSearch, options]);

  const checkAddressLine = () => {
    if (
      getSearchParameter(name)
        .split(",")
        .some((filter) =>
          options.map((option) => option.value).includes(filter)
        )
    ) {
      let uuids = getSearchParameter(name).split(",");
      if (
        JSON.stringify([...uuids].sort()) !== JSON.stringify([...values].sort())
      ) {
        onChange(
          uuids.filter(
            (item) =>
              options.map((option) => option.value).includes(item) &&
              !values.includes(item)
          )
        );
      }
      if (
        uuids.length !==
        uuids.filter((item) =>
          options.map((option) => option.value).includes(item)
        ).length
      ) {
        history.push(
          setSearchParameter(
            name,
            uuids.filter((item) =>
              options.map((option) => option.value).includes(item)
            )
          )
        );
      }
    } else {
      history.push(deleteSearchParameters([name]));
    }
  };

  const handleClearAll = (event) => {
    event.stopPropagation();
    onChange([]);
    history && history.push(deleteSearchParameters([name]));
    setSelectOpened(false);
  };

  const handleSelectAll = (event) => {
    event.stopPropagation();
    const newValues = options.map((option) => option.value);
    onChange(newValues);
    history && history.push(setSearchParameter(name, newValues));
  };

  const handleSelectOption = (value) => {
    values.includes(value)
      ? onChange(values.filter((item) => item !== value))
      : onChange([...values, value]);
    if (history) {
      if (values.includes(value)) {
        history.push(
          values.filter((item) => item !== value).length !== 0
            ? setSearchParameter(
                name,
                values.filter((item) => item !== value)
              )
            : deleteSearchParameters([name])
        );
      } else {
        (!checkSearchParameter(name) ||
          getSearchParameter(name).split(",").length < values.length + 1) &&
          history.push(setSearchParameter(name, [...values, value]));
      }
    }
  };

  const dropdownMenu = (
    <div
      style={floatingStyles}
      ref={refs.setFloating}
      className={cx("filter__multiple-dropdown-menu", { right: rightAlign })}
    >
      {searchable && (
        <div className="filter__multiple-dropdown-menu__search">
          <Search />
          <input
            type="text"
            placeholder="Search"
            autoFocus={true}
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
        </div>
      )}

      <div className="filter__multiple-dropdown-menu__buttons">
        <button
          className="filter__main-button"
          onClick={(event) =>
            values.length === 0 ? handleSelectAll(event) : handleClearAll(event)
          }
        >
          {values.length === 0 ? "Select All" : "Clear All"}
        </button>
      </div>

      <ul
        className={cx("filter__multiple-dropdown-menu__options", {
          searchable,
        })}
      >
        {options
          .filter((option) =>
            option.label.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((option) => {
            const { value } = option;
            return (
              <li
                key={value}
                className={cx("option", { active: values.includes(value) })}
                onClick={() => handleSelectOption(value)}
              >
                {values.includes(value) && <Done />}
                <span className="option__label">{option.label}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div
      ref={refs.setReference}
      className={cx("multiple-select-filter", {
        opened: selectOpened,
        disabled,
      })}
    >
      <div
        className="filter__toggle"
        onClick={() => {
          !disabled && setSelectOpened((prev) => !prev);
        }}
      >
        <span className="filter__label">
          {title}{" "}
          {values.length === options.length ? "All" : `(${values.length})`}
        </span>
        <ArrowDropDown />
        {clearable && (
          <button className="filter__clear" onClick={handleClearAll}>
            <Close />
          </button>
        )}
      </div>
      {selectOpened &&
        createPortal(dropdownMenu, document.getElementById("root"))}
    </div>
  );
};
