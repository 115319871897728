import { useEffect } from "react";

import "moment-timezone";

import { ReactComponent as Clear } from "../../../assets/img/icons/datepicker/Clear.svg";

import { intervalsDate } from "../../../constants/analytics.constants";

import "../DateTimepicker.scss";

export const CustomIntervalSelect = ({
  selectMax = intervalsDate.length,
  selectMin = 1,
  onChange,
  onCancel,
  openCalendar,
  timeInterval,
  value,
}) => {
  useEffect(() => {
    document.addEventListener("click", checkClick);
  }, []);

  function checkClick(e) {
    if (
      !(e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementById("datepicker_ranges__container")
      )
    ) {
      onCancel(false);
      return document.removeEventListener("click", checkClick);
    }
  }

  const changeInterval = (value) => {
    if (value !== "select") {
      onChange(value, null);
    } else {
      onChange(value, value);
    }
  };

  return (
    <div className="datepicker-ranges__container">
      <ul
        className="datepicker-ranges datepicker-ranges__left-aligned"
        id="datepicker_ranges__container"
      >
        {intervalsDate.slice(selectMin, selectMax).map((item, i) => (
          <li
            key={i}
            className={`datepicker-ranges-item ${
              timeInterval &&
              timeInterval !== null &&
              item.icon === timeInterval.icon
                ? "datepicker-ranges-item__active"
                : ""
            }`}
            onClick={() => {
              changeInterval(item.icon);
            }}
          >
            {item.label}
          </li>
        ))}
        <li
          className={`datepicker-ranges-item ${
            timeInterval === null && value !== null && value !== "select"
              ? "datepicker-ranges-item__active"
              : ""
          }`}
          onClick={() => openCalendar()}
        >
          Custom
        </li>
        <li
          className="datepicker-ranges-item datepicker-ranges-item__clear"
          onClick={() => {
            changeInterval("select");
          }}
        >
          <Clear />
          Clear
        </li>
      </ul>
    </div>
  );
};
