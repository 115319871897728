import * as types from "../actions/pinsAction";

const initialState = {
  loading: false,
  materialType: "select",
  search: "",
  data: [],
};

const pinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PINS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.GET_PINS:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_PINS_SUCCESS:
      return {
        ...state,
        ...((state.materialType === action.params.type ||
          (state.materialType === "select" && !action.params.type)) &&
        (state.search === action.params.search ||
          (!state.search && !action.params.search))
          ? {
              loading: false,
              data: action.payload
                .map((typedMaterials) =>
                  typedMaterials.materials.map((material) => ({
                    type: typedMaterials.type,
                    uuid: material.materialUuid,
                    name: material.name,
                    pins: material.pins.map((pin) => pin.toString()),
                  }))
                )
                .flat(),
            }
          : {}),
      };

    case types.GET_PINS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };

    case types.RESET_PINS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default pinsReducer;
