import { getAnalytics } from "../../api/history";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_TRANSACTIONS_ANALYTIC_VALUE =
  "CHANGE_TRANSACTIONS_ANALYTIC_VALUE";
export const CHANGE_TRANSACTIONS_ANALYTIC_SEVERAL_VALUES =
  "CHANGE_TRANSACTIONS_ANALYTIC_SEVERAL_VALUES";
export const RESET_TRANSACTIONS_ANALYTIC_STORE =
  "RESET_TRANSACTIONS_ANALYTIC_STORE";

export const GET_TRANSACTIONS_ANALYTIC = "GET_TRANSACTIONS_ANALYTIC";
export const GET_TRANSACTIONS_ANALYTIC_SUCCESS =
  "GET_TRANSACTIONS_ANALYTIC_SUCCESS";
export const GET_TRANSACTIONS_ANALYTIC_ERROR =
  "GET_TRANSACTIONS_ANALYTIC_ERROR";

export const getTransactionsAnalyticAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_TRANSACTIONS_ANALYTIC });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({
            type: GET_TRANSACTIONS_ANALYTIC_SUCCESS,
            payload: results,
          });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_TRANSACTIONS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_TRANSACTIONS_ANALYTIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_TRANSACTIONS_ANALYTIC_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_TRANSACTIONS_ANALYTIC_STORE,
});
