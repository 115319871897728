import {
  getDoneRecommendations,
  getCsvExport,
} from "../../api/doneRecommendation";
import { downloadFile } from "../../utils";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_RECOMMENDATION_DONE_VALUE =
  "CHANGE_RECOMMENDATION_DONE_VALUE";
export const CHANGE_RECOMMENDATION_DONE_SEVERAL_VALUES =
  "CHANGE_RECOMMENDATION_DONE_SEVERAL_VALUES";
export const RESET_RECOMMENDATION_DONE_STORE =
  "RESET_RECOMMENDATION_DONE_STORE";

export const GET_RECOMMENDATION_DONE = "GET_RECOMMENDATION_DONE";
export const GET_RECOMMENDATION_DONE_SUCCESS =
  "GET_RECOMMENDATION_DONE_SUCCESS";
export const GET_RECOMMENDATION_DONE_ERROR = "GET_RECOMMENDATION_DONE_ERROR";

export const LOAD_REC_DONE_FILE = "LOAD_REC_DONE_FILE";
export const LOAD_REC_DONE_FILE_SUCCESS = "LOAD_REC_DONE_FILE_SUCCESS";
export const LOAD_REC_DONE_FILE_ERROR = "LOAD_REC_DONE_FILE_ERROR";

export const getRecDoneAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_RECOMMENDATION_DONE });
      try {
        const response = await getDoneRecommendations(
          params,
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_RECOMMENDATION_DONE_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_RECOMMENDATION_DONE_ERROR });
          actions?.onError(error.message);
        }
      }
    };
  };
})();

export const downloadRecDoneFile = (params) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_REC_DONE_FILE });
    try {
      const response = await getCsvExport(params);
      const type = "text/csv";
      const { success, results } = response;
      if (success) {
        downloadFile(results, type);
        dispatch({ type: LOAD_REC_DONE_FILE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_REC_DONE_FILE_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_RECOMMENDATION_DONE_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_RECOMMENDATION_DONE_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_RECOMMENDATION_DONE_STORE,
});
