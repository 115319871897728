import React from "react";

import * as R from "./constants/routes.constants";

const LoginPage = React.lazy(() => import("./containers/SignIn/Login"));
const SettingsPage = React.lazy(() => import("./containers/Settings/Settings"));
const HistoryPage = React.lazy(() => import("./containers/History/History"));
const HistoryAnalyticsPage = React.lazy(() =>
  import("./containers/History/Analytics")
);
const RecHistoryPage = React.lazy(() =>
  import("./containers/RecHistory/RecHistory")
);
const QuestionsPage = React.lazy(() =>
  import("./containers/Questions/Questions")
);
const SupportHistoryPage = React.lazy(() =>
  import("./containers/SupportHistory/SupportHistory")
);
const TransactionsPage = React.lazy(() =>
  import("./containers/Transactions/Transactions")
);
const CateringPage = React.lazy(() =>
  import("./containers/Transactions/Catering")
);
const TransactionsAnalyticPage = React.lazy(() =>
  import("./containers/Transactions/Analytics")
);
const ActiveSessionsPage = React.lazy(() =>
  import("./containers/UserProfile/ActiveSessions/ActiveSessions")
);
const AuthLogPage = React.lazy(() =>
  import("./containers/UserProfile/AuthentificationLog/AuthentificationLog")
);
const MultiFactorPage = React.lazy(() =>
  import("./containers/SignIn/MultiFactorAuth")
);
const DlpsPage = React.lazy(() => import("./containers/Dlps/Dlps"));
const PinsPage = React.lazy(() => import("./containers/Pins/Pins"));

const routes = [
  { id: 1, path: R.LOGIN, component: LoginPage },
  { id: 2, path: R.SETTINGS, component: SettingsPage },
  { id: 3, path: R.ORDERS_HISTORY, component: HistoryPage },
  { id: 4, path: R.REC_HISTORY, component: RecHistoryPage },
  { id: 5, path: R.QUESTIONS, component: QuestionsPage },
  { id: 6, path: R.AUTHENTIFICATION_LOG, component: AuthLogPage },
  { id: 7, path: R.TRANSACTIONS_INFO, component: TransactionsPage },
  { id: 8, path: R.SUPPORT_HISTORY, component: SupportHistoryPage },
  { id: 9, path: R.MULTI_FACTOR_AUTH, component: MultiFactorPage },
  {
    id: 10,
    path: R.TRANSACTIONS_ANALYTIC,
    component: TransactionsAnalyticPage,
  },
  { id: 11, path: R.ORDERS_ANALYTICS, component: HistoryAnalyticsPage },
  { id: 12, path: R.ACTIVE_SESSIONS, component: ActiveSessionsPage },
  { id: 13, path: R.AUTHENTIFICATION_LOG, component: AuthLogPage },
  { id: 14, path: R.DLPS, component: DlpsPage },
  { id: 15, path: R.PINS, component: PinsPage },
  { id: 16, path: R.CATERING, component: CateringPage },
];

export default routes;
