import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import cx from "classnames";
import moment from "moment";
import "moment-timezone";

import { ReactComponent as ArrowDropDown } from "../../assets/img/icons/common/ArrowDropDown.svg";
import { ReactComponent as Clock } from "../../assets/img/icons/common/Clock.svg";
import { ReactComponent as Calendar } from "../../assets/img/icons/datepicker/Calendar.svg";
import { ReactComponent as Clear } from "../../assets/img/icons/datepicker/Clear.svg";

import { DateModal } from "../Modal/MobileModal/DateModal";
import { TimeControlWithOptions } from "../TimeControls/TimeControlWithOptions";

import { getLocalTimestamp } from "../../utils/commonMethods";

import "./DateTimepicker.scss";

export const MobileDatepicker = ({
  start,
  end,
  timeInterval = null,
  withoutTime = false,
  maxInterval = null,
  addNotification = null,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  onChange,
  allowFuture = false,
  defaultValue = null,
}) => {
  const [time, setTime] = useState([
    timeInterval !== null
      ? moment(
          getLocalTimestamp(Date.now() - timeInterval * 1000, timezone)
        ).format("HH:mm")
      : start !== null && start !== "select"
      ? moment(start).format("HH:mm")
      : "00:00",
    timeInterval
      ? moment(getLocalTimestamp(Date.now(), timezone)).format("HH:mm")
      : end !== null && end !== "select"
      ? moment(end).format("HH:mm")
      : "23:59",
  ]);
  const [dateModal, setDateModal] = useState(false);

  useEffect(() => {
    if (
      defaultValue !== null &&
      start === null &&
      end === null &&
      timeInterval === null
    ) {
      onChange({ startDate: null, endDate: null, timeInterval: defaultValue });
      setTime([
        getDateWithInterval(defaultValue, "HH:mm"),
        moment(getLocalTimestamp(Date.now(), timezone)).format("HH:mm"),
      ]);
    }
  }, []);

  const changeTime = (time) => {
    if (
      start !== "select" &&
      [start, timeInterval, defaultValue].some((val) => val !== null)
    ) {
      let startTime = time[0].split(":");
      let endTime = time[1].split(":");
      let startDate =
        start !== null
          ? start
          : getDateWithInterval(timeInterval || defaultValue, "MMM D, YYYY");
      let endDate =
        end !== null ? end : getLocalTimestamp(Date.now(), timezone);
      onChange({
        startDate: Number(
          moment(startDate)
            .set({ h: startTime[0], m: startTime[1] })
            .format("x")
        ),
        endDate: Number(
          moment(endDate).set({ h: endTime[0], m: endTime[1] }).format("x")
        ),
        timeInterval: null,
      });
    }
  };

  useEffect(() => {
    if (
      end !== null &&
      moment(end).format("MMM D, YYYY") === moment().format("MMM D, YYYY")
    ) {
      setTime([
        time[0],
        moment(getLocalTimestamp(Date.now(), timezone)).format("HH:mm"),
      ]);
    } else if (
      (defaultValue !== null || timeInterval !== null) &&
      end === null
    ) {
      setTime([
        getDateWithInterval(timeInterval || defaultValue, "HH:mm"),
        moment(getLocalTimestamp(Date.now(), timezone)).format("HH:mm"),
      ]);
    }
  }, [timezone]);

  const getDateWithInterval = (interval, format) => {
    return moment(
      getLocalTimestamp(Date.now() - interval * 1000, timezone)
    ).format(format);
  };

  const setNewDates = (start, end) => {
    if (
      moment(end).format("MMM D, YYYY") === moment().format("MMM D, YYYY") &&
      !allowFuture
    ) {
      setTime([
        time[0],
        moment(getLocalTimestamp(Date.now(), timezone)).format("HH:mm"),
      ]);
      onChange({
        startDate: start,
        endDate: getLocalTimestamp(Date.now(), timezone),
        timeInterval: null,
      });
    } else {
      onChange({
        startDate: start,
        endDate: end + 86340000,
        timeInterval: null,
      });
      setTime(["00:00", "23:59"]);
    }
  };

  const checkSameDates = (date1, date2 = []) => {
    return moment(date1).isSame(moment(date2), "date");
  };

  return (
    <div className="mobile-datepicker__container mt-24">
      <div className="mobile-datepicker">
        <span>From</span>
        <div className="mobile-datepicker__row">
          <div
            className="mobile-datepicker__item"
            onClick={() => setDateModal(true)}
          >
            <div
              className={cx("mobile-datepicker__item-date", {
                empty:
                  (start === null && defaultValue === null) ||
                  start === "select",
              })}
            >
              <Calendar />
              {defaultValue !== null && start === null
                ? getDateWithInterval(
                    timeInterval || defaultValue,
                    "MMM D, YYYY"
                  )
                : start !== null && start !== "select"
                ? moment(start).format("MMM D, YYYY")
                : "Select Date"}
            </div>
            <ArrowDropDown />
          </div>
          {!withoutTime && (
            <div
              className={cx("mobile-datepicker__item-time__container", {
                empty:
                  (start === null && defaultValue === null) ||
                  start === "select",
              })}
            >
              <div className="mobile-datepicker__clock">
                <Clock />
              </div>
              <div className="mobile-datepicker__clock-arrow">
                <ArrowDropDown />
              </div>
              <TimeControlWithOptions
                className="mobile-datepicker__item-time"
                interval={15}
                time={time[0]}
                maxTime={
                  start !== "select" &&
                  checkSameDates(
                    start ||
                      getDateWithInterval(timeInterval || defaultValue, "x")
                  )
                    ? moment().tz(timezone).format("HH:mm")
                    : undefined
                }
                onChange={(e) => {
                  setTime([e, time[1]]);
                  changeTime([e, time[1]]);
                }}
              />
            </div>
          )}
        </div>
        <span>To</span>
        <div className="mobile-datepicker__row">
          <div
            className="mobile-datepicker__item"
            onClick={() => setDateModal(true)}
          >
            <div
              className={cx("mobile-datepicker__item-date", {
                empty:
                  (end === null && defaultValue === null) || end === "select",
              })}
            >
              <Calendar />
              {defaultValue !== null && end === null
                ? moment(getLocalTimestamp(Date.now(), timezone)).format(
                    "MMM D, YYYY"
                  )
                : end !== null && end !== "select"
                ? moment(end).format("MMM D, YYYY")
                : "Select Date"}
            </div>
            <ArrowDropDown />
          </div>
          {!withoutTime && (
            <div
              className={cx("mobile-datepicker__item-time__container", {
                empty:
                  (start === null && defaultValue === null) ||
                  start === "select",
              })}
            >
              <div className="mobile-datepicker__clock">
                <Clock />
              </div>
              <div className="mobile-datepicker__clock-arrow">
                <ArrowDropDown />
              </div>
              <TimeControlWithOptions
                className={cx("mobile-datepicker__item-time", {
                  empty:
                    (end === null && defaultValue === null) || end === "select",
                })}
                hideInterval={
                  !checkSameDates(
                    start !== null && start !== "select"
                      ? start
                      : getDateWithInterval(timeInterval || defaultValue, "x"),
                    start !== null && start !== "select" ? end : null
                  )
                }
                interval={15}
                startTime={
                  checkSameDates(
                    start !== null && start !== "select"
                      ? start
                      : getDateWithInterval(timeInterval || defaultValue, "x"),
                    start !== null && start !== "select" ? end : null
                  )
                    ? time[0]
                    : undefined
                }
                time={time[1]}
                maxTime={
                  timeInterval !== null ||
                  (defaultValue !== null && end === null) ||
                  (end !== "select" && checkSameDates(end))
                    ? moment().tz(timezone).format("HH:mm")
                    : undefined
                }
                onChange={(e) => {
                  setTime([time[0], e]);
                  changeTime([time[0], e]);
                }}
              />
            </div>
          )}
        </div>
        <div
          className={cx("mobile-datepicker__clear", {
            disabled:
              (start === null && defaultValue === null) || start === "select",
          })}
          onClick={() => {
            setTime(["00:00", "23:59"]);
            onChange({
              timeInterval: null,
              startDate: "select",
              endDate: "select",
            });
          }}
        >
          <Clear />
          Clear
        </div>
      </div>
      {dateModal && (
        <DateModal
          dates={[
            start !== null && start !== "select" ? new Date(start) : new Date(),
            end !== null && end !== "select" ? new Date(end) : new Date(),
          ]}
          allowFuture={allowFuture}
          maxInterval={maxInterval}
          addNotification={addNotification}
          onConfirm={(start, end) => {
            setNewDates(
              new Date(start).getTime() * 1000,
              new Date(end).getTime() * 1000
            );
            setDateModal(false);
          }}
          onCancel={() => setDateModal(false)}
        />
      )}
    </div>
  );
};
