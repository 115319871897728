import React from "react";
import cx from "classnames";

import "./MainLargeButton.scss";

export const MainLargeButton = ({
  className,
  label,
  disabled,
  width,
  onClick,
}) => {
  return (
    <button
      className={cx("main-large-button", className)}
      disabled={disabled}
      onClick={onClick}
      style={{ width: width || "auto" }}
    >
      {label}
    </button>
  );
};
