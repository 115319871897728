export const LOGIN = "/login";
export const SETTINGS = "/settings";
export const ORDERS_HISTORY = "/orders/history";
export const ORDERS_ANALYTICS = "/orders/analytics";
export const REC_HISTORY = "/recommendations/history";
export const QUESTIONS = "/questions";
export const SUPPORT_HISTORY = "/support-history";
export const TRANSACTIONS_INFO = "/transactions/history";
export const CATERING = "/transactions/catering";
export const TRANSACTIONS_ANALYTIC = "/transactions/analytics";
export const USER_PROFILE = "/user-settings/profile";
export const AUTHENTIFICATION_LOG = "/user-settings/authentification-log";
export const ACTIVE_SESSIONS = "/user-settings/active-sessions";
export const MULTI_FACTOR_AUTH = "/multi-factor-auth";
export const DLPS = "/dlps";
export const PINS = "/pins";
