import {
  deleteSupport,
  resolveSupport,
  getSupportHistory,
} from "../../api/support";
import { handleActionAbortController } from "../../utils/handleActionAbortController";

export const CHANGE_SUPPORT_HISTORY_VALUE = "CHANGE_SUPPORT_HISTORY_VALUE";
export const CHANGE_SUPPORT_HISTORY_SEVERAL_VALUES =
  "CHANGE_SUPPORT_HISTORY_SEVERAL_VALUES";
export const SET_SUPPORT_HISTORY_ATTACHMENT = "SET_SUPPORT_HISTORY_ATTACHMENT";
export const RESET_SUPPORT_HISTORY_STORE = "RESET_SUPPORT_HISTORY_STORE";

export const GET_SUPPORT_HISTORY = "GET_SUPPORT_HISTORY";
export const GET_SUPPORT_HISTORY_SUCCESS = "GET_SUPPORT_HISTORY_SUCCESS";
export const GET_SUPPORT_HISTORY_ERROR = "GET_SUPPORT_HISTORY_ERROR";

export const DELETE_SUPPORT = "DELETE_SUPPORT";
export const DELETE_SUPPORT_SUCCESS = "DELETE_SUPPORT_SUCCESS";
export const DELETE_SUPPORT_ERROR = "DELETE_SUPPORT_ERROR";

export const getSupportHistoryAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_SUPPORT_HISTORY });
      try {
        const response = await getSupportHistory(
          params,
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({
            type: GET_SUPPORT_HISTORY_SUCCESS,
            payload: results,
            params: params,
          });
          actions?.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_SUPPORT_HISTORY_ERROR });
        }
      }
    };
  };
})();

export const deleteSupportAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SUPPORT });
    try {
      const response = await deleteSupport(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_SUPPORT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error);
      dispatch({ type: DELETE_SUPPORT_ERROR });
    }
  };
};

export const resolveSupportAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await resolveSupport(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_SUPPORT_HISTORY_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_SUPPORT_HISTORY_SEVERAL_VALUES,
  payload: value,
});

export const setReportAttachment = (reportUuid, index, src, success) => ({
  type: SET_SUPPORT_HISTORY_ATTACHMENT,
  payload: { reportUuid, index, src, success },
});

export const resetStore = () => ({
  type: RESET_SUPPORT_HISTORY_STORE,
});
