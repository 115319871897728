import moment from "moment";
import * as types from "../actions/transactionsAnalyticAction";

const initialState = {
  content: null,
  locationNums: [],
  timezone: null,
  activeTab: "graphic",
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  loading: false,
};

const transactionsAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TRANSACTIONS_ANALYTIC_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_TRANSACTIONS_ANALYTIC_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_TRANSACTIONS_ANALYTIC:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TRANSACTIONS_ANALYTIC_SUCCESS:
      return {
        ...state,
        loading: false,
        content: {
          restaurants:
            action.payload.length !== 0
              ? action.payload.map((item) => item.locationNum)
              : [],
          graphicValues:
            action.payload.length !== 0
              ? action.payload[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  ...action.payload.map((it) =>
                    Object.values(it.values[index][1]).reduce(function (
                      sum,
                      elem
                    ) {
                      return sum + Number(elem);
                    },
                    0)
                  ),
                ])
              : [],
          totalValues: action.payload
            .map((item) => ({
              restaurant: item.locationNum,
              value: item.values.reduce(function (sum, elem) {
                return (
                  sum +
                  Number(
                    Object.values(elem[1]).reduce(function (summ, el) {
                      return summ + Number(el);
                    }, 0)
                  )
                );
              }, 0),
            }))
            .sort((a, b) => b.value - a.value),
        },
      };

    case types.GET_TRANSACTIONS_ANALYTIC_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_TRANSACTIONS_ANALYTIC_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default transactionsAnalyticReducer;
