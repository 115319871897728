import cx from "classnames";

import "./FormTextArea.scss";

export const FormTextArea = ({
  className,
  name,
  value,
  disabled,
  title,
  onChange,
}) => {
  const handleChange = (e) => {
    name ? onChange(name, e.target.value) : onChange(e.target.value);
  };

  return (
    <div className={cx("form-textarea__container", { disabled: disabled })}>
      <div
        className={cx("form-textarea__title", {
          filled: !!value && value.length !== 0,
        })}
      >
        {title}
      </div>

      <textarea
        className={cx("form-textarea", className)}
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
};
