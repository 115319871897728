import request from "../utils/request";

export function getQuestions(params, signal) {
  return request({
    url: "/question/list",
    method: "get",
    data: params,
    signal,
  });
}

export function deleteQuestion(uuid) {
  return request({
    url: `/question/${uuid}`,
    method: "delete",
  });
}

export function addQuestion(params) {
  return request({
    url: `/question`,
    method: "post",
    data: params,
  });
}

export function answerQuestion(params) {
  return request({
    url: `/question/answer`,
    method: "post",
    data: params,
  });
}
