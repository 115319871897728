import "./Footer.scss";

import { ReactComponent as Email } from "../../assets/img/icons/common/Email.svg";

export const LoginFooter = () => {
  return (
    <footer className="login-container__footer">
      <div className="login-container__footer__container login-container__footer__container-left">
        <div className="login-container__footer-item disabled-text">
          © {new Date().getFullYear()} Chick-fil-A
        </div>
      </div>
      <div className="login-container__footer__container login-container__footer__container-right">
        <a
          className="login-container__footer__email"
          href="mailto:support@cfasmartrestaurant.com"
          target="_blank"
        >
          <Email />
          support@cfasmartrestaurant.com
        </a>
      </div>
    </footer>
  );
};
